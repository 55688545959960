import styled from 'styled-components';
import colors from '../../../config/colors';

const H1 = styled.h1`
  font-size: 44px;
  color: ${colors.BLACK_COLOR};
  margin: 0 0 10px;

  @media (max-width: 767px) {
    font-size: 26px;
  }
`;

export default H1;
