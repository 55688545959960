import React, {FC, memo, useEffect, useState} from 'react';

// Styles
import styled from 'styled-components';
import colors from '../../../config/colors';
import P from '../../../components/Paragraph';
import HeroImage from "../../../assets/Images/banner-image.webp";
import Search from './Search/Loadable';
import H1 from '../../../components/Headings/H1';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const HeroSection: FC = () => {

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <HeroSectionWrapper>
      <HeroOrangeBackground/>
      <HeroPurpleBackground/>
      <HeroContentWrapper>
        {width > 576 ? 
        <HeroOrangeImage>
          <picture>
            <source srcSet={HeroImage} type="image/webp" />
            <img src={HeroImage} className="hero-image" alt="Hero" onError={(e: any) => e.target.style.display = 'none'} />
          </picture>
        </HeroOrangeImage>
        : null}
        <HeroSearch>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} duration={1} delay={300}>
            <H1>Tvoj krug prilika</H1>
            <P>… a prilike nisu samo oglasi za posao!</P>
          </AnimationOnScroll>
          <Search />
        </HeroSearch>
      </HeroContentWrapper>
      <Circle
        thickness={'6px'}
        color={'#fff'}
        size={'300px'}
        opacity={'0.3'}
        right={'-80px'}
        bottom={'-25px'}>
      </Circle>
      <Circle
        thickness={'50px'}
        color={'#fff'}
        size={'450px'}
        opacity={'0.1'}
        right={'-150px'}
        bottom={'-95px'}>
      </Circle>
    </HeroSectionWrapper>
  )
}

const HeroSectionWrapper = styled.div`
  position: relative;
  display: flex;
  height: 810px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 700px;
  }

  @media (max-width: 768px) {
    height: 620px;
  }

  @media (max-width: 576px) {
    height: 100%;
  }

  .hero-image {
    position: absolute;
    bottom: 0;
    right: -50px;
    width: 624px;
    height: 595px;

    @media (max-width: 1024px) {
      width: 370px;
      height: 352px;
      right: 0;
      left: 0;
      margin: auto;
    }

    @media (max-width: 768px) {
      width: 240px;
      height: 228px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const HeroContentWrapper = styled.div`
  display: flex;
  width: 100%;
  z-index: 2;
  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const HeroOrangeBackground = styled.div`
  background: radial-gradient(50% 50% at 50% 50%, #FEFEFE 0%, #F6BF51 100%);
  width: 39%;
  position: absolute;
  left: 0;
  height: 100%;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

const HeroPurpleBackground = styled.div`
  width: 61%;
  background: linear-gradient(79.46deg, #634098 7.84%, #9671CE 102.08%);
  position: absolute;
  right: 0;
  height: 100%;

  @media (max-width: 1024px) {
    width: 100%;
    height: 57%;
  }

  @media (max-width: 768px) {
    height: 68%;
  }

  @media (max-width: 576px) {
    height: 100%;
  }
`;

const HeroOrangeImage = styled.div`
  flex: 0 0 39%;
  position: relative;

  @media (max-width: 1024px) {
    flex: 0 0 100%;
    flex: 2;
    position: inherit;
  }
`;

const HeroSearch = styled.div`
  margin: auto;
  width: 600px;
  padding: 30px 15px;
  @media (max-width: 768px) {
   width: auto;
  }
  h1 {
    color: ${colors.WHITE_COLOR};
    font-size: 56px;
    line-height: 66px;
    margin: 0;

    @media (max-width: 576px) {
      font-size: 34px;
      line-height: 44px;
    }
  }

  p {
    font-family: "Gilroy-Medium";
    color: ${colors.WHITE_COLOR};
    max-width: 453px;
    margin: 0 0 20px;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
`;

const Circle = styled.div<{ thickness: string, color: string, size: string, opacity: string, right: string, bottom: string }>`
  border: ${props => props.thickness} solid ${props => props.color};
  position: absolute;
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  border-radius: 50%;
  width: ${props => props.size};
  height: ${props => props.size};
  opacity: ${props => props.opacity};

  @media (max-width: 992px) {
    display: none;
  }
`;

export default memo(HeroSection);
